@font-face {
  font-family: OpenSans;
  font-weight: lighter;
  font-style: normal;
  src: url(../fonts/OpenSans-Light.ttf);
}
@font-face {
  font-family: OpenSans;
  font-weight: lighter;
  font-style: italic;
  src: url(../fonts/OpenSans-LightItalic.ttf);
}
@font-face {
  font-family: OpenSans;
  font-weight: normal;
  font-style: normal;
  src: url(../fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: OpenSans;
  font-weight: normal;
  font-style: italic;
  src: url(../fonts/OpenSans-Italic.ttf);
}
@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: normal;
  src: url(../fonts/OpenSans-Bold.ttf);
}
@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: italic;
  src: url(../fonts/OpenSans-BoldItalic.ttf);
}

* {
  outline: none;
}

body {
  overflow-y: scroll;
}

html,
body {
  margin: 0;
  background: #fafafa;
  font-family: OpenSans;
  font-weight: normal;
}

.facebook-button,
.google-button {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
}

.toast {
  padding: 10px;
  box-sizing: border-box;
}

.toast-content {
  background: #2b2726;
  padding: 15px;
  line-height: 20px;
  font-family: OpenSans;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  min-height: 30px;
  margin-top: 10px;
}

.slick-slide {
  height: 100%;
}
.slick-list {
  overflow: hidden;
}
.slick-track {
  display: flex;
}
.slick-dots {
  display: flex !important;
  list-style: none;
  flex-direction: row;
  padding: 0;
  justify-content: center;
  margin-bottom: 0;
}
.slick-dots button {
  border: none;
  background: #9e9e9e;
  width: 10px;
  height: 10px;
  display: block;
  font-size: 0;
  padding: 0;
  border-radius: 10px;
  margin: 2px;
  cursor: pointer;
}
.slick-dots .slick-active button {
  background: #5f4ffa;
}
